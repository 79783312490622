<template>

  <auth-content>
    <el-header :title="$t('modules.wiki.tokens.title')" slot="header">
      <div slot="left">
        <el-header-back />
      </div>
      <div slot="actions">
        <el-header-action :disabled="loading" size="xs" icon="plus3" :label="$t('modules.wiki.tokens.button_text_add')" @click.native="tokenEdit=tokenAdd; openModal('wiki-token-edit')"/>
      </div>
    </el-header>

    <div>
      <el-filters :filters="filters"/>

      <el-table
        :total="Object.keys(tokens).length"
        :columns="columns"
        :data="tokensFiltered"
      >

        <div slot="actions" slot-scope="{row}">
          <el-table-action
            @click.prevent.native="tokenEdit=row; openModal('wiki-token-edit')"
            icon="icon-pen6"
            :title="$t('modules.wiki.tokens.actions.edit')">
          </el-table-action>
          <el-table-action
            v-if="isCurrentWorldOwner && row.references.length == 0"
            @click.prevent.native="confirmTokenRemove(row.id)"
            icon="icon-bin"
            :title="$t('modules.wiki.tokens.actions.delete')">
          </el-table-action>
        </div>

      </el-table>
    </div>

    <wiki-tokens-edit-modal :token="tokenEdit" :callback="loadTokens" />

    <el-modal size="sm" :title="$t('modules.wiki.tokens.actions.delete')" id="wiki-token-remove">
      <form @submit.prevent="removeToken()">
        <div class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_token_confirm">
            {{ $t('modules.wiki.tokens.remove_confirmation_text') }}
          </label>
        </div>
        <div class="text-center">
          <button v-if="!removeLoading" type="submit" class="btn btn-danger text-white">{{ $t('common.submit') }}</button>
          <div v-if="removeLoading" class="pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </el-modal>

  </auth-content>

</template>

<script>

import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '@/store'
import WikiTokensEditModal from '../components/TokensEditModal'

import {
  WIKI_GET_TOKENS,
  WIKI_SET_TOKEN,
  WIKI_DELETE_TOKEN,
} from '../mutations-types'

export default {
  name: 'WikiTokensIndex',
  components: {
    WikiTokensEditModal
  },
  data () {
    return {
      remove_token_confirm: false,
      remove_token_id: null,
      tokenAdd: {
        type: 'string',
        key: '',
        data: {
          value: '',
        },
      },
      tokenEdit: false,
      editLoading: false,
      removeLoading: false,
      tokens: false,
      columns: {
        key: this.$t('modules.wiki.tokens.fields.key'),
        value: this.$t('modules.wiki.tokens.fields.value'),
        used_in: this.$t('modules.wiki.tokens.fields.used_in'),
      },
      filters: {}
    }
  },
  mounted () {
  },
  methods: {
    loadTokens () {
      this.$store.dispatch('wiki/' + WIKI_GET_TOKENS, this.$route.params.world).then(tokens => {
        this.tokens = tokens
        this.loadFilters()
      })
    },
    loadFilters () {
      let tokens = {...this.formatTokens()}
      this.filters = {}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('modules.wiki.tokens.fields.used'),
          key: 'used',
          options: this.getFilterOptions(tokens, 'used'),
        }
      ])
    },
    formatTokens () {
      let tokensArray = []

      if (this.tokens) {
        // Get tokens
        _.map(this.tokens, (elem) => {
          let token = {
            id: elem.id,
            key: elem.key,
            data: elem.data,
            value: elem.value,
            references: elem.references,
            type: elem.type,
            used_in: this.$tc('modules.wiki.tokens.used_in', elem.references.length),
            used: elem.references.length == 0 ? this.$tc('modules.wiki.tokens.not_used') : this.$tc('modules.wiki.tokens.used'),
          }
          tokensArray.push(token);
        })
      }
      return tokensArray
    },
    editToken () {
      this.editLoading = true
      this.$store.dispatch('wiki/' + WIKI_SET_TOKEN, {
        id: this.tokenEdit.id,
        world_id: this.$route.params.world,
        key: this.tokenEdit.key,
        value: this.tokenEdit.data.value
      }).then(data => {
        this.editLoading = false
        this.notifSuccess(this.$t('modules.wiki.tokens.messages.edit_success'))
        this.loadTokens()
        this.closeModal('wiki-token-edit')
        this.tokenEdit = {}
      })
      .catch(error => {
        this.editLoading = false
        this.notifError(this.$t('modules.wiki.tokens.messages.edit_error'))
      })
    },
    confirmTokenRemove (tokenId) {
      this.remove_token_confirm = false
      this.remove_token_id = tokenId
      this.openModal('wiki-token-remove');
    },
    removeToken () {
      this.removeLoading = true
      if (this.remove_token_confirm) {
        this.$store.dispatch('wiki/' + WIKI_DELETE_TOKEN, {
          world_id: this.$route.params.world,
          id: this.remove_token_id
        }).then(data => {
          this.removeLoading = false
          this.notifSuccess(this.$t('modules.wiki.tokens.messages.delete_success'))
          this.loadTokens()
          this.closeModal('wiki-token-remove')
          this.remove_token_id = null
          this.remove_token_confirm = false
        })
        .catch(error => {
          this.removeLoading = false
          this.notifError(this.$t('modules.wiki.tokens.messages.delete_error'))
        })
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('wiki/' + WIKI_GET_TOKENS, to.params.world).then(tokens => {
      next(vm => {
        vm.tokens = {...tokens}
        vm.loadFilters()
      })
    })
  },
  computed: {
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    tokensFormatted () {
      return this.formatTokens()
    },
    tokensFiltered () {

      let searchValue = this.$store.state.global.sidebarSearch
      if (searchValue === '') {
        return this.applyDataFilters(this.tokensFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.tokensFormatted, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
          "value"
        ]
      }).search(searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
